import React from "react";

import styles from "./footer.module.css";

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div>Homework DBS Team</div>
    </div>
  );
};

export default Footer;
